<template>
    <div class="wrapper">
        <HeaderNav v-if="!$store.state.isNavBarOff" :auth="$store.state.isAuth"></HeaderNav>
        <v-form ref="form" lazy-validation>
            <div class="outerUpload">
                <div class="title__wrap">
                    <h1>Подтвердите email</h1>
                    <p>
                        Для завершения регистрации перейдите по&nbsp;ссылке, отправленной
                        на&nbsp;указанный email
                    </p>
                </div>
                <div class="inputs">
                    <p class="check__subtitle">Email</p>
                    <v-text-field
                        v-model="this.$store.state.email"
                        placeholder="Email"
                        disabled
                        dense
                        outlined
                        class="check-input__disabled"
                        required
                        clearable
                    >
                    </v-text-field>
                </div>
                <v-btn
                    depressed
                    :color="'primary'"
                    class="next-btn"
                    block
                    @click="sendMessageEmail"
                >
                    <div class="btn-content-container">
                        <img
                            src="@/assets/loader.svg"
                            alt="loader"
                            class="loader-icon"
                            :class="{ hidden: !isLoading }"
                        />
                        Отправить ссылку
                    </div></v-btn
                >
            </div>
        </v-form>
    </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import jwt_decode from "jwt-decode";
import axios from "axios";

export default {
    props: {},
    data() {
        return {
            user_id: jwt_decode(this.$cookies.get("cng_token"))["id"],
            isLoading: false,
        };
    },
    methods: {
        async sendMessageEmail() {
            try {
                this.isLoading = true;

                await axios.post(`${process.env.VUE_APP_MYHOST}/sendemail`, {
                    email: this.$store.state.email,
                });

                this.isLoading = false;
                this.$router.push({ name: "LcMain" }).catch(() => {});
            } catch (error) {
                console.error(error.message);

                this.isLoading = false;
            }
        },
    },

    components: { HeaderNav },
};
</script>

<style lang="scss" scoped>
.wrapper {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}
.title__wrap {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 32px;
}
.title__wrap h1 {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.005em;

    color: #1a1a1a;
}

.title__wrap p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;
    color: #ef2322;
    border-radius: 6px;
    background: #fcd3d3;
    align-self: center;
    padding: 12px 16px;
}

.v-form h1 {
    font-family: "PT Sans";
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.005em;
    text-align: center;
}

.v-form,
.form {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.v-form {
    background: #fff;
    width: 100%;
}
.inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    gap: 4px;
}
.check-input__disabled {
    background-color: #f1f1f1 !important;
    height: 44px;
    border-radius: 10px;
}
.check__subtitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #999999;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    margin-bottom: 6px !important;
}

.next-btn {
    font-weight: 700;
    font-size: 16px;
    height: 44px !important;
    border-radius: 12px !important;
    line-height: 24px;
    margin-bottom: 12px;
    margin: 0 !important;
}
.politic {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;

    color: #868686;
}
.politic a {
    color: #868686;
}
.v-btn:not(.v-btn--round).v-size--default {
    border-radius: var(--border-radius) !important;
}
.check-input__disabled {
    border-radius: 8px !important;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
    .v-form {
        max-width: 800px;
        width: 100%;
        border-radius: 10px;
        padding: 60px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title__wrap h1 {
        font: 700 40px "PT Sans";
        line-height: 110%; /* 44px */
        letter-spacing: -0.2px;
    }
}
</style>
