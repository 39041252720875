import { render, staticRenderFns } from "./PassRegist.vue?vue&type=template&id=dc9b7c16&scoped=true"
import script from "./PassRegist.vue?vue&type=script&lang=js"
export * from "./PassRegist.vue?vue&type=script&lang=js"
import style0 from "./PassRegist.vue?vue&type=style&index=0&id=dc9b7c16&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc9b7c16",
  null
  
)

export default component.exports