import { render, staticRenderFns } from "./TitleProfile.vue?vue&type=template&id=5daaa619&scoped=true"
import script from "./TitleProfile.vue?vue&type=script&lang=js"
export * from "./TitleProfile.vue?vue&type=script&lang=js"
import style0 from "./TitleProfile.vue?vue&type=style&index=0&id=5daaa619&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5daaa619",
  null
  
)

export default component.exports