<template>
    <div class="wrapper">
        <div class="check-titleBlock">
            <h1 class="check-titleBlock__title">Мои чеки</h1>
            <div class="check-title__counter">чеков: {{ checkList.length }}</div>
        </div>
        <div
            class="check-container"
            v-for="check in checkList"
            :key="check.id"
            @click="$router.push({ name: 'LcSingle', params: { id: check.id } }).catch(() => {})"
        >
            <div class="check__date">
                {{ getDate(check.create_date) }}
            </div>
            <div
                class="check__status"
                :class="{
                    verified: check.status === 'verified',
                    wait: check.status === 'wait',
                    notVerified: check.status === 'notVerified',
                    onHold: check.status === 'onHold',
                }"
            >
                {{ statuses[check.status] }}
            </div>
            <div class="check__arrow"></div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        checkList: { type: Array, required: true },
    },
    data() {
        return {
            statuses: {
                wait: "Проверка",
                verified: "Принято",
                notVerified: "Отклонено",
                onHold: "На ожидании",
            },
        };
    },
    methods: {
        getDate(fullDate) {
            return moment(fullDate).utc().local().format("DD.MM.YYYY HH:mm");
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    margin-bottom: 20px !important;
    margin-top: 0 !important;
    padding: 0 !important;
    gap: 0px !important;
}
.check-titleBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}
.check-titleBlock__title {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #1a1a1a;
}
.check-title__counter {
    min-width: 107px;
    min-height: 26px;
    text-align: center;
    background: var(--main-color);
    border-radius: 8px;
}
.check-title__counter {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: -0.005em;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.check-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    background: #f6f6f6;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.015em;
    color: #1a1a1a;
    margin-bottom: 12px;
    padding: 15px 8px;
    cursor: pointer;
}
.check__date {
    text-align: start;
    width: 54%;
    min-width: 121px;
    padding-right: 12px;
}
.check__status {
    font-size: 12px;
    background: #f5dfcc;
    border-radius: 8px;
    width: 30%;
    padding: 5px 10px;
    width: 100px;
}
.check__status.wait {
    background: #b8b8b8;
}
.check__status.verified {
    background: #deecdc;
}
.check__status.notVerified {
    background: #fae3de;
}
.check__status.onHold {
    background: #7a96e3;
}
.check__arrow {
    position: absolute;
    right: 12px;
    min-width: 10px;
    height: 10px;
    background-image: url("../assets/arrow.svg");
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
    .check-titleBlock h1 {
        font: 700 40px "PT Sans";
        line-height: 110%; /* 44px */
        letter-spacing: -0.2px;
    }
}
</style>
