var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[(!_vm.$store.state.isNavBarOff)?_c('HeaderNav',{attrs:{"auth":_vm.$store.state.isAuth}}):_vm._e(),_c('v-form',{ref:"form",staticClass:"apruve-container",attrs:{"lazy-validation":""}},[_c('div',{staticClass:"outer"},[_c('div',{staticClass:"title__wrap"},[_c('h1',[_vm._v("Персональные данные")]),_c('p',[_vm._v("Для участия в акции укажите ФИО и номер телефона для связи")])]),_c('div',{staticClass:"inputs"},[_c('v-text-field',{staticClass:"fio-field email-field",class:{ 'error-input': this.fioRules.length > 0 },attrs:{"label":"ФИО","autocomplete":"name","rules":_vm.fioRules,"dense":"","outlined":"","required":"","clearable":"","autofocus":""},model:{value:(_vm.fio),callback:function ($$v) {_vm.fio=$$v},expression:"fio"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ###-##-##'),expression:"'+7 (###) ###-##-##'"}],staticClass:"email-field",class:{ 'error-input': this.phoneRules.length > 0 },attrs:{"label":"Телефон","placeholder":"+7","rules":_vm.phoneRules,"dense":"","outlined":"","clearable":""},on:{"focus":_vm.focus,"focusout":_vm.focusout,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return (() => {
                            if (_vm.phone?.length <= 2) {
                                $event.preventDefault();
                            }
                        }).apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined))return null;return (() => {
                            if (_vm.phone?.length <= 2) {
                                $event.preventDefault();
                            }
                        }).apply(null, arguments)}]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-btn',{staticClass:"next-btn",attrs:{"depressed":"","color":'primary',"block":""},on:{"click":_vm.register}},[_vm._v("Сохранить")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }